/*eslint-disable*/
import { Autocomplete, FormControl, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MDInput from "components/MDInput";
import { setReportContent, setReportContentDate, useMaterialUIController } from "context";
import { ptBR } from "date-fns/locale";
import PlanningLayout from "examples/LayoutContainers/PlanningLayout";
import DataTable from "examples/Tables/DataTable";
import * as React from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Axios from "utils/axiosInstance";
import { formatDate } from "utils/formatDate";
import QuoteReports from "./quoteReports";

const statusDict = {
  HEADER: "Cadastro Cabeçalho",
  SERVICES_AND_PRODUCTS: "Cadastro Serviço e Produtos",
  CHECKUP: "Finalizar Planejamento",
  SET_PRICES: "Cadastro Preço",
  FINISH: "Planejamento Final",
  OS: "Ordem de Serviço",
  BASE: "Serviço Base",
};

const filterOptions = ["ID", "CNPJ", "Cidade", "Estado", "Telefone"];
const filterOptionsSafra = ["NOME"];
const filterOptionsCultura = ["NOME"];

export default function ReportsList() {
  const [, dispatch] = useMaterialUIController();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);
  const [fieldName, setFieldName] = React.useState(filterOptions[0]);
  const [program, setProgram] = React.useState([]);
  const [customerName, setCustomerName] = React.useState([]);
  const [startDate, setStartDate] = useState("");
  const [finishDate, setFinishDate] = useState("");
  const [pageOptions, setPageOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [selectCustomer, setSelectCustomer] = React.useState();
  const [selectedReportType, setSelectedReportType] = useState(null);
  const [selectedSoil, setSelectedSoil] = useState([{ id: 0, name: "" }]);
  const [soil, setSoil] = useState([{ id: 0, name: "Todas as Glebas" }]);
  const [status, setStatus] = useState([]);
  const [multipleStatus, setMultipleStatus] = useState([]);
  const [farms, setFarms] = useState([]);
  const [farm, setFarm] = useState([]);
  const [programList, setProgramList] = useState([{ id: 0, name: "Todos os Programas" }]);
  const [plan, setPlan] = useState([]);
  const [plans, setPlans] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [programs, setPrograms] = useState([]);
  const [reports, setReports] = useState([]);
  const [plansShopping, setPlansShopping] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [servicesAll, setServicesAll] = useState([]);

  React.useEffect(() => {
    sessionStorage.setItem("layout", "reports");
  }, [pathname]);

  const user = JSON.parse(localStorage.getItem("user"));

  const axios = Axios();
  const columns = [
    { accessor: "plan.customer.name", Header: "Cliente", width: 100 },
    {
      accessor: "actions",
      Header: "Ações",
      width: 150,
      Cell: ({ row }) =>
        selectedReportType?.id === 3 ? (
          <QuoteReports />
        ) : (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault(); // Prevenir o comportamento padrão do link

              let reportUrl;
              if (selectedReportType?.id === 1) {
                reportUrl = "/relatorioAtividade";
              } else if (selectedReportType?.id === 2) {
                reportUrl = "/relatorioCompras";
              } else if (selectedReportType?.id === 4) {
                reportUrl = "/relatorioOrcamento";
              } else if (selectedReportType?.id === 5) {
                reportUrl = "/relatorioProgramaManejo";
              }

              window.open(reportUrl, "_blank"); // Abrir em nova guia
            }}
          >
            <Button
              id="demo-customized-button"
              variant="contained"
              textColor="white"
              style={{ color: "white" }}
            >
              Exibir Relatório
            </Button>
          </a>
        ),
    },
  ];

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue);
    if (finishDate && newValue > finishDate) {
      setFinishDate(null);
    }
  };

  const handleFinishDateChange = (newValue) => {
    if (startDate && newValue < startDate) {
      return;
    }
    setFinishDate(newValue);
  };

  const statusList = [
    {
      id: "PENDING",
      name: "Pendente",
      color: "#007aa2",
    },
    {
      id: "foreseen",
      name: "Previsto",
      color: "#7D9D4A",
    },
    {
      id: "foreseen_delayed",
      name: "Previsto Atrasado",
      color: "#F9B208",
    },
    {
      id: "done",
      name: "Finalizado",
      color: "#666666",
    },
    {
      id: "done_delayed",
      name: "Finalizado Atrasado",
      color: "#4a4a4a",
    },
  ];

  const statusListShopping = [
    {
      id: "PENDING",
      name: "Pendente",
      color: "#007aa2",
    },
    {
      id: "foreseen",
      name: "Previsto",
      color: "#7D9D4A",
    },

    {
      id: "done",
      name: "Finalizado",
      color: "#666666",
    },
  ];

  const typesRelatorios = [
    { id: 1, name: "Atividade" },
    { id: 2, name: "Compras" },
    { id: 3, name: "Cotação" },
    { id: 4, name: "Orçamento" },
    { id: 5, name: "Programa de Manejo" },
  ];

  const renderFilterComponents = () => {
    const navigate = useNavigate();
    switch (selectedReportType?.name) {
      case "Atividade":
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <div style={{ width: "500px" }}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="farm"
                  options={farms}
                  value={farm || ""}
                  multiple={true}
                  getOptionLabel={(option) => option?.name}
                  onChange={(_, value) => {
                    setFarm(value);
                    // Verifica se a fazenda selecionada ainda possui solo selecionado
                    const selectedSoilExists = value.some(
                      (selectedFarm) => selectedFarm.id === selectedSoil.id
                    );
                    if (!selectedSoilExists) {
                      setSoil("");
                      setSelectedSoil("");
                    }
                  }}
                  onInputChange={(event, value, reason) => {
                    if (reason === "clear") {
                      setSoil("");
                      setSelectedSoil("");
                    }
                  }}
                  renderOption={(props, option) => (
                    <li {...props} optionId={option.id}>
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => <MDInput {...params} label="Fazenda" />}
                />
              </FormControl>
            </div>
            <div
              style={{
                marginLeft: "1.3%",
                width: "25%",
              }}
            >
              <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Periodo Inicial"
                  value={startDate}
                  inputFormat="dd/MM/yyyy"
                  autoFocus
                  openOnFocus
                  onChange={handleStartDateChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{ ...params.inputProps, placeholder: "dd/mm/aaaa" }}
                      style={{ width: "100%" }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div style={{ marginLeft: "1.3%", width: "300px" }}>
              <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Periodo Final"
                  inputFormat="dd/MM/yyyy"
                  autoFocus
                  value={finishDate}
                  openOnFocus
                  onChange={handleFinishDateChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{ ...params.inputProps, placeholder: "dd/mm/aaaa" }}
                      style={{ width: "100%" }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div
              style={{
                marginLeft: "1.3%",
              }}
            >
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  multiple={true}
                  options={[{ id: 0, name: "Selecionar Todas" }, ...soil]}
                  value={selectedSoil || []}
                  onInputChange={(event, value, reason) => {
                    if (reason === "clear") {
                      setSelectedSoil([]);
                      setSelectedService([]);
                      setServices(
                        servicesAll.filter(
                          (service, index, self) =>
                            index === self.findIndex((s) => s.id === service.id)
                        )
                      );
                    }
                  }}
                  getOptionLabel={(option) => option?.name}
                  id="combo-box-demo"
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="Gleba" />}
                  renderTags={(value, getTagProps) =>
                    selectedSoil.length > 1 ? (
                      <Chip label={`${selectedSoil.length} Glebas selecionadas`} />
                    ) : (
                      <Chip variant="outlined" label={selectedSoil[0]?.name || ""} />
                    )
                  }
                  disabled={farm.length > 1 || farm.length === 0}
                  onChange={(event, value) => {
                    // Se a opção "Selecionar Todas" estiver marcada, seleciona todas as glebas
                    if (value.some((option) => option.id === 0)) {
                      setSelectedSoil(soil);
                      // setServices(servicesAll);
                    } else {
                      setSelectedSoil(value);
                    }
                  }}
                />
              </FormControl>
            </div>
            <div
              style={{
                marginLeft: "1.3%",
              }}
            >
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  multiple={true}
                  options={[{ id: 0, name: "Selecionar Todos" }, ...services]}
                  value={selectedService || []}
                  onInputChange={(event, value, reason) => {
                    if (reason === "clear") {
                      setSelectedService([]);
                    }
                  }}
                  getOptionLabel={(option) => option?.name}
                  id="select-service-combo"
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="Serviços" />}
                  renderTags={(value, getTagProps) =>
                    selectedService.length > 1 ? (
                      <Chip label={`${selectedService.length} Serviços selecionadas`} />
                    ) : (
                      <Chip variant="outlined" label={selectedService[0]?.name || ""} />
                    )
                  }
                  // disabled={services.length > 0 || farm.length === 0}
                  onChange={(event, value) => {
                    // Se a opção "Selecionar Todas" estiver marcada, seleciona todas as glebas
                    if (value.some((option) => option.id === 0)) {
                      setSelectedService(services);
                    } else {
                      setSelectedService(value);
                    }
                  }}
                />
              </FormControl>
            </div>
            <div
              style={{
                marginLeft: "1.3%",
              }}
            >
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  disablePortal
                  options={statusList}
                  getOptionLabel={(option) => option?.name}
                  id="combo-box-demo"
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="Status de Serviço" />}
                  onChange={(_, value) => setMultipleStatus(value)}
                  renderTags={(value, getTagProps) => (
                    <div>
                      {value.length > 1 ? (
                        <Chip label={`${value.length} Status selecionados`} />
                      ) : (
                        <Chip variant="outlined" label={value[0]?.name || ""} />
                      )}
                    </div>
                  )}
                />
              </FormControl>
            </div>
          </div>
        );

      case "Compras":
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <div style={{ width: "300px" }}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="farm"
                  options={farms}
                  value={farm}
                  multiple={true}
                  getOptionLabel={(option) => option?.name}
                  onChange={(_, value) => setFarm(value)}
                  renderOption={(props, option) => (
                    <li {...props} optionId={option.id}>
                      {option.name}
                    </li>
                  )}
                  renderTags={(value, getTagProps) =>
                    farm.length > 1 ? (
                      <Chip label={`${farm.length} Fazendas selecionadas`} />
                    ) : (
                      <Chip variant="outlined" label={farm[0]?.name || ""} />
                    )
                  }
                  renderInput={(params) => <MDInput {...params} label="Fazenda" />}
                />
              </FormControl>
            </div>
            <div
              style={{
                marginLeft: "1.3%",
                width: "300px",
              }}
            >
              <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Periodo Inicial"
                  value={startDate}
                  inputFormat="dd/MM/yyyy"
                  autoFocus
                  openOnFocus
                  onChange={handleStartDateChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{ ...params.inputProps, placeholder: "dd/mm/aaaa" }}
                      style={{ width: "100%" }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div style={{ marginLeft: "1.3%", width: "300px" }}>
              <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Periodo Final"
                  inputFormat="dd/MM/yyyy"
                  autoFocus
                  value={finishDate}
                  openOnFocus
                  onChange={handleFinishDateChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{ ...params.inputProps, placeholder: "dd/mm/aaaa" }}
                      style={{ width: "100%" }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div
              style={{
                marginLeft: "1.3%",
              }}
            >
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  options={statusListShopping}
                  getOptionLabel={(option) => option?.name}
                  id="combo-box-demo"
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="Status de Serviço" />}
                  onChange={(_, value) => setStatus(value)}
                />
              </FormControl>
            </div>
            <div style={{ width: "300px", marginLeft: "1.3%", marginRight: "1.3%" }}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="farm"
                  options={plansShopping}
                  value={{ id: 0, name: plan.name ?? "" }}
                  onInputChange={(event, value, reason) => {
                    if (reason === "clear") {
                      setPlan("");
                    }
                  }}
                  getOptionLabel={(option) => option?.name}
                  onChange={(_, value) => setPlan(value)}
                  renderOption={(props, option) => (
                    <li {...props} optionId={option.id}>
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => <MDInput {...params} label="Planejamento" />}
                />
              </FormControl>
            </div>
          </div>
        );
      case "Cotação":
        return (
          <div
            style={{
              display: "flex",
              marginLeft: "5.3%",
            }}
          >
            <div style={{ width: "300px" }}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="farm"
                  options={plans}
                  value={plan}
                  multiple={true}
                  onInputChange={(event, value, reason) => {
                    if (reason === "clear") {
                      setProgram([]);
                      setProgramList([]);
                      setReports([]);
                    }
                  }}
                  getOptionLabel={(option) => option?.name}
                  onChange={(_, value) => setPlan(value)}
                  renderOption={(props, option) => (
                    <li {...props} optionId={option.id}>
                      {option.name}
                    </li>
                  )}
                  renderTags={(value, getTagProps) =>
                    plan.length > 1 ? (
                      <Chip label={`${plan.length} Planejamentos selecionados`} />
                    ) : (
                      <Chip variant="outlined" label={plan[0]?.name || ""} />
                    )
                  }
                  renderInput={(params) => <MDInput {...params} label="Planejamento" />}
                />
              </FormControl>
            </div>
            {/* <div style={{ width: "300px", marginLeft: "1.3%" }}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="ProgramList"
                  options={[{ id: 0, name: "Selecionar todos" }, ...programList]} // Certifique-se de que programList seja um array
                  value={selectedProgram || "" || []}
                  multiple={true}
                  getOptionLabel={(option) => option?.name || ""}
                  onChange={(event, value) => {
                    // Se a opção "Selecionar Todas" estiver marcada, seleciona todas as glebas
                    if (value.some((option) => option.id === 0)) {
                      setSelectedProgram(programList);
                    } else {
                      setSelectedProgram(value);
                    }
                  }}
                  renderOption={(props, option) => (
                    <li {...props} optionId={option.id}>
                      {option.name}
                    </li>
                  )}
                  renderTags={(value, getTagProps) =>
                    selectedProgram.length > 1 ? (
                      <Chip label={`${selectedProgram.length} Programas selecionadas`} />
                    ) : (
                      <Chip variant="outlined" label={selectedProgram[0]?.name || ""} />
                    )
                  }
                  renderInput={(params) => <MDInput {...params} label="Programa" />}
                />
              </FormControl>
            </div> */}
          </div>
        );
      case "Orçamento":
        return (
          <div
            style={{
              display: "flex",
              marginLeft: "20px",
            }}
          >
            <div style={{ width: "300px" }}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="farm"
                  options={plans}
                  onInputChange={(event, value, reason) => {
                    if (reason === "clear") {
                      setReports([]);
                      setPlan([]);
                    }
                  }}
                  getOptionLabel={(option) => option?.name}
                  onChange={(_, value) => setPlan(value)}
                  renderOption={(props, option) => (
                    <li {...props} optionId={option.id}>
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => <MDInput {...params} label="Planejamento" />}
                />
              </FormControl>
            </div>
          </div>
        );
      case "Programa de Manejo":
        return (
          <div
            style={{
              display: "flex",
              marginLeft: "2.3%",
              gap: "10px",
            }}
          >
            <div style={{ width: "300px" }}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="farm"
                  options={farms}
                  value={farm}
                  multiple={true}
                  getOptionLabel={(option) => option?.name}
                  onChange={(_, value) => {
                    setFarm(value);
                  }}
                  onInputChange={(event, value, reason) => {
                    if (reason === "clear") {
                      setProgram("");
                      setSelectedProgram("");
                      setFarm("");
                      setProgramList("");
                      setProgram("");
                    }
                  }}
                  renderOption={(props, option) => (
                    <li {...props} optionId={option.id}>
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => <MDInput {...params} label="Fazenda" />}
                  renderTags={(value, getTagProps) =>
                    farm.length > 1 ? (
                      <Chip label={`${farm.length} Fazendas selecionadas`} />
                    ) : (
                      <Chip variant="outlined" label={farm[0]?.name || ""} />
                    )
                  }
                />
              </FormControl>
            </div>

            <div style={{ width: "300px" }}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="farm"
                  options={plans}
                  value={plan}
                  multiple={true}
                  onInputChange={(event, value, reason) => {
                    if (reason === "clear") {
                      setProgram([]);
                      setProgramList([]);
                      setFarm([]);
                      setSelectedProgram(null);
                    }
                  }}
                  getOptionLabel={(option) => option?.name}
                  onChange={(_, value) => setPlan(value)}
                  renderOption={(props, option) => (
                    <li {...props} optionId={option.id}>
                      {option.name}
                    </li>
                  )}
                  renderTags={(value, getTagProps) =>
                    plan.length > 1 ? (
                      <Chip label={`${plan.length} Planejamentos selecionados`} />
                    ) : (
                      <Chip variant="outlined" label={plan[0]?.name || ""} />
                    )
                  }
                  renderInput={(params) => <MDInput {...params} label="Planejamento" />}
                />
              </FormControl>
            </div>

            <div style={{ width: "300px", marginLeft: "1.3%" }}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="ProgramList"
                  options={[{ id: 0, name: "Selecionar todos" }, ...programList]} // Certifique-se de que programList seja um array
                  value={selectedProgram || "" || []}
                  multiple={true}
                  getOptionLabel={(option) => option?.name || ""}
                  onChange={(event, value) => {
                    // Se a opção "Selecionar Todas" estiver marcada, seleciona todas as glebas
                    if (value.some((option) => option.id === 0)) {
                      setSelectedProgram(programList);
                    } else {
                      setSelectedProgram(value);
                    }
                  }}
                  renderOption={(props, option) => (
                    <li {...props} optionId={option.id}>
                      {option.name}
                    </li>
                  )}
                  renderTags={(value, getTagProps) =>
                    selectedProgram.length > 1 ? (
                      <Chip label={`${selectedProgram.length} Glebas selecionadas`} />
                    ) : (
                      <Chip variant="outlined" label={selectedProgram[0]?.name || ""} />
                    )
                  }
                  renderInput={(params) => <MDInput {...params} label="Glebas" />}
                />
              </FormControl>
            </div>
          </div>
        );
    }
  };

  React.useEffect(() => {
    if (selectCustomer) {
      axios.get(`/v1/farm/${selectCustomer.id}/customer`).then((res) => {
        setFarms(res.data);
      });

      axios.get(`/v1/plans/${selectCustomer.id}/customer`).then((res) => {
        setPlans(res.data);
      });
    }
    setFarm([]);
    setProgramList([]);
    setSelectedProgram("");
    setSelectedReportType("");
    setSoil("");
    setSelectedSoil("");
  }, [selectCustomer]);

  React.useEffect(() => {
    if (servicesAll.length > 0 && selectedSoil.length > 0) {
      const filteredServices = servicesAll.filter((service) => {
        return selectedSoil.map((soil) => soil.id).includes(service.soil.id);
      });

      const uniqueServices = filteredServices.filter(
        (service, index, self) => index === self.findIndex((s) => s.id === service.id)
      );

      uniqueServices.sort((a, b) => (a.name > b.name ? 1 : -1));

      setServices(uniqueServices);
    }
  }, [selectedSoil]);

  React.useEffect(() => {
    if (farm.length > 0 && plans.length > 0) {
      farm.map((fow) => {
        const services = programs
          .flatMap((pow) => pow)
          .filter((plan) => plan.plan.farm.id === fow.id)
          .flatMap((pow) => {
            return pow.services.map((sow) => {
              return {
                ...sow,
                soil: pow.soil,
              };
            });
          });

        const uniqueServices = services
          .filter((service, index, self) => index === self.findIndex((s) => s.id === service.id))
          .sort((a, b) => (a.name > b.name ? 1 : -1));

        setServicesAll(services);
        setServices(uniqueServices);
      });
    }
  }, [farm, programs]);

  React.useEffect(() => {
    if (farm.length > 0) {
      farm.map((fow) => {
        axios.get(`/v1/soil/${fow.id}/farm`).then((res) => {
          if (res.data.length > 0) {
            setSoil([
              ...soil,
              ...res.data.map((soil) => ({ ...soil, name: soil.farm.acronym + " - " + soil.name })),
            ]);
          }
        });

        axios.get(`/v1/plans/${fow.id}/farm`).then((res) => {
          if (res.data.length > 0) {
            setPlans(res.data);
            setPlansShopping(res.data.filter((plan) => plan.farm.id === fow.id));
          }
        });
      });
    }
  }, [farm]);

  React.useEffect(() => {
    if (selectedReportType?.id === 1 || selectedReportType?.id === 2) {
      plans.map((fow) => {
        axios.get(`/v1/program/${fow.id}/plan`).then((res) => {
          if (res.data.length > 0) {
            res.data.map((data) => {
              setPrograms((program) => [...program, data]);
            });
          }
        });
      });
    }
  }, [plans]);

  React.useEffect(() => {
    if (Array.isArray(plan) && selectedReportType?.id === 5) {
      plan.map((fow) =>
        axios.get(`/v1/program/${fow.id}/plan`).then((res) => {
          if (res.data.length > 0) {
            res.data.map((data) => {
              setPrograms((programs) => [...programs, data]);
              setProgramList((programList) => [
                ...programList,
                {
                  ...data,
                  name: data.plan.farm.acronym + " - " + data.soil ? data.soil.name : "SEM GLEBA",
                },
              ]);
            });
          }
        })
      );
    }
  }, [plan]);

  // React.useEffect(() => {
  //   setReports([]);

  //   if (selectedReportType?.id === 3) {
  //     plan.flatMap((pow) => {
  //       pow.services.map((sow) => {
  //         setReports((reports) => [
  //           ...reports,
  //           {
  //             ...pow,
  //             plan: pow,
  //             program_service: sow,
  //           },
  //         ]);
  //       });
  //     });
  //   } else if (selectedReportType?.id === 4) {
  //     plan.services.map((sow) => {
  //       setReports((reports) => [
  //         ...reports,
  //         {
  //           ...plan,
  //           plan: plan,
  //           program_service: sow,
  //         },
  //       ]);
  //     });
  //   } else if (selectedReportType?.id === 5) {
  //   }
  // }, [plan]);

  function isDateInRange(dateToCheck, startDate, endDate) {
    dateToCheck = dateToCheck instanceof Date ? dateToCheck : new Date(dateToCheck);
    startDate = startDate instanceof Date ? startDate : new Date(startDate);
    endDate = endDate instanceof Date ? endDate : new Date(endDate);

    return dateToCheck >= startDate && dateToCheck <= endDate;
  }

  const isRange = (startDate, finishDate, sow) => {
    if (!startDate && !finishDate) return false;
    return (
      isDateInRange(sow.start, startDate, finishDate) ||
      isDateInRange(sow.end, startDate, finishDate) ||
      isDateInRange(sow.newDateInitial, startDate, finishDate) ||
      isDateInRange(sow.newDateFinish, startDate, finishDate)
    );
  };

  const isSoil = (ssoil, sow) => {
    if (Array.isArray(ssoil)) {
      return (
        ssoil.map((soil) => soil.id).includes(sow.soil) ||
        ssoil.map((soil) => soil.id).includes(sow.soil.id)
      );
    }
  };

  const isStatus = (status, sow) => {
    if (!status) return false;
    return sow.status === status.id;
  };

  const isMultipleStatus = (status, sow) => {
    if (Array.isArray(multipleStatus)) {
      return multipleStatus.map((status) => status.id).includes(sow.status);
    }
  };

  const isPlan = (plan, pow) => {
    if (!plan || plan.length === 0) return false;

    return plan.id === pow.plan.id;
  };

  const isProgram = (program, pow) => {
    if (!program) return false;

    if (Array.isArray(program)) {
      return program.some((item) => item.id === pow.id);
    } else {
      return pow.id === program.id;
    }
  };

  const isServices = (services, sow) => {
    if (!services || !Array.isArray(services)) return false;

    return services.map((service) => service.name).includes(sow.name);
  };

  React.useEffect(() => {
    const addReport = (report) => {
      setReports((reports) => [...reports, report]);
    };

    const createFilter = (sow, reportType, pow) => ({
      ...pow,
      program_service: sow,
      filter: {
        report: reportType,
        startDate: startDate ? startDate.toLocaleDateString() : "",
        finishDate: finishDate ? finishDate.toLocaleDateString() : "",
        ...(status && { status }),
      },
    });

    setReports([]);

    if (selectedReportType?.id === 3 && plan.length > 0) {
      plan.flatMap((pow) => {
        pow.services.map((sow) => {
          addReport({
            ...pow,
            plan: pow,
            program_service: sow,
          });
        });
      });
    }

    if (selectedReportType?.id === 4 && !Array.isArray(plan)) {
      if (!plan) return;

      plan.services.map((sow) => {
        addReport({
          ...plan,
          plan: plan,
          program_service: sow,
        });
      });
    }

    if (
      selectedReportType?.id === 5 &&
      farm &&
      Array.isArray(farm) &&
      selectedProgram &&
      plan.length > 0
    ) {
      programs.flatMap((pow) => {
        pow.services.map((sow) => {
          if (isProgram(selectedProgram, pow)) {
            addReport({
              ...pow,
              program_service: sow,
            });
          }
        });
      });
    }

    programs.flatMap((pow) => {
      pow.services.forEach((sow) => {
        let shouldAddReport = false;
        let reportType = "";

        switch (selectedReportType?.id) {
          case 1:
            shouldAddReport =
              isRange(startDate, finishDate, sow) &&
              isSoil(selectedSoil, sow) &&
              isServices(selectedService, sow) &&
              isMultipleStatus(status, sow);
            reportType = "Atividade";
            break;
          case 2:
            shouldAddReport =
              isStatus(status, sow) && isRange(startDate, finishDate, sow) && isPlan(plan, pow);
            reportType = "Compras";
            break;
          default:
            // Tratamento para outros tipos de relatório, se houver
            break;
        }

        if (selectedReportType?.id !== 3 && shouldAddReport) {
          const report = createFilter(sow, reportType, pow);

          addReport(report);
        }
      });
    });
  }, [
    startDate,
    finishDate,
    selectedSoil,
    status,
    selectedProgram,
    plan,
    selectedService,
    selectedReportType,
    farm.length,
    multipleStatus,
  ]);

  // React.useEffect(() => {
  //   setReports([]);

  //   programs.flatMap((pow) => {
  //     pow.services.map((sow, index) => {
  //       const isRange = (startDate, finishDate, sow) => {
  //         if (!startDate && !finishDate) return false;

  //         return (
  //           isDateInRange(sow.start, startDate, finishDate) ||
  //           isDateInRange(sow.end, startDate, finishDate) ||
  //           isDateInRange(sow.newDateInitial, startDate, finishDate) ||
  //           isDateInRange(sow.newDateFinish, startDate, finishDate)
  //         );
  //       };

  //       const isSoil = (ssoil, sow) => {
  //         if (Array.isArray(ssoil)) {
  //           return (
  //             ssoil.map((soil) => soil.id).includes(sow.soil) ||
  //             ssoil.map((soil) => soil.id).includes(sow.soil.id)
  //           );
  //         }
  //       };

  //       const isStatus = (status, sow) => {
  //         if (!status) return false;
  //         return sow.status === status.id;
  //       };

  //       const isPlan = (plan, pow) => {
  //         if (!plan || plan.length === 0) return false;

  //         return plan.id === pow.plan.id;
  //       };

  //       const isProgram = (program, pow) => {
  //         if (!program) return false;

  //         // Verifica se `program` é um array
  //         if (Array.isArray(program)) {
  //           return program.some((item) => item.id === pow.id);
  //         } else {
  //           return pow.id === program.id;
  //         }
  //       };

  //       const isServices = (services, sow) => {
  //         if (!services || !Array.isArray(services)) return false;

  //         return services.map((service) => service.name).includes(sow.name);
  //       };

  //       if (selectedReportType?.id === 1) {
  //         if (farm.length > 1) {
  //           if (isRange(startDate, finishDate, sow) && isStatus(status, sow)) {
  //             setReports((reports) => [
  //               ...reports,
  //               {
  //                 ...pow,
  //                 program_service: sow,
  //                 filter: {
  //                   report: "Atividade",
  //                   startDate: startDate.toLocaleDateString(),
  //                   finishDate: finishDate.toLocaleDateString(),
  //                 },
  //               },
  //             ]);
  //           }
  //         } else if (
  //           isRange(startDate, finishDate, sow) &&
  //           isSoil(selectedSoil, sow) &&
  //           isServices(selectedService, sow) &&
  //           isStatus(status, sow)
  //         ) {
  //           setReports((reports) => [
  //             ...reports,
  //             {
  //               ...pow,
  //               program_service: sow,
  //               filter: {
  //                 report: "Atividade",
  //                 startDate: startDate.toLocaleDateString(),
  //                 finishDate: finishDate.toLocaleDateString(),
  //                 status,
  //               },
  //             },
  //           ]);
  //         }
  //       } else if (selectedReportType?.id === 2) {
  //         if (isStatus(status, sow) && isRange(startDate, finishDate, sow) && isPlan(plan, pow)) {
  //           setReports((reports) => [
  //             ...reports,
  //             {
  //               ...pow,
  //               program_service: sow,
  //               filter: {
  //                 report: "Compras",
  //                 startDate: startDate.toLocaleDateString(),
  //                 finishDate: finishDate.toLocaleDateString(),
  //                 status,
  //               },
  //             },
  //           ]);
  //         }
  //       }
  //        else if (selectedReportType?.id === 3) {

  //           setReports((reports) => [
  //             ...reports,
  //             {
  //               ...pow,
  //               plan: pow.plan,
  //             },
  //           ]);
  //       }
  //        else if (selectedReportType?.id === 4) {
  //         if (isProgram(selectedProgram, pow)) {
  //           setReports((reports) => [
  //             ...reports,
  //             {
  //               ...pow,
  //               program_service: sow,
  //             },
  //           ]);
  //         }
  //       } else if (selectedReportType?.id === 5) {
  //         if (isProgram(selectedProgram, pow)) {
  //           setReports((reports) => [
  //             ...reports,
  //             {
  //               ...pow,
  //               program_service: sow,
  //             },
  //           ]);
  //         }
  //       }
  //     });
  //   });
  // }, [startDate, finishDate, selectedSoil, status, selectedProgram, plan, selectedService]);

  React.useEffect(() => {
    if (reports.length > 0) {
      setReportContent(dispatch, reports);
      setReportContentDate(dispatch, { startDate, finishDate });

      localStorage.setItem("reports", JSON.stringify(reports));
    }
  }, [reports]);

  function removeDuplicateDates(dateArray) {
    const uniqueDates = [...new Set(dateArray)];
    return uniqueDates;
  }
  function removeDuplicateDates(dateArray) {
    const uniqueDates = [...new Set(dateArray)];
    return uniqueDates;
  }

  React.useEffect(() => {
    const uniqueCustomers = {};

    const filteredReports = reports.filter((report) => {
      const customerName = report.plan.customer.name;
      if (!uniqueCustomers[customerName]) {
        uniqueCustomers[customerName] = true;
        return true;
      }
      return false;
    });

    const uniqueDates = removeDuplicateDates(filteredReports.map((report) => report.date));

    setRows(filteredReports);
    setTotalRows(filteredReports.length);
    const options = [];
    for (let x = 0; x < Math.ceil(filteredReports.length / rowsPerPage); x++) {
      options.push(x + 1);
    }
    setPageOptions(options);
    setLoading(false);
  }, [reports, rowsPerPage]);

  React.useEffect(() => {
    setFarm("");
    setSoil("");
    setSelectedSoil("");
    axios.get(`v1/customer`).then((res) => {
      const data = res.data.data.map((row) => ({
        ...row,
        name: row.name,
        id: row.id,
      }));

      setCustomerName(data);

      if (user?.customer) {
        const customer = data.find((customer) => customer.id === user.customer.id);
        setSelectCustomer(customer);
      }
    });

    setLoading(true);
    setRows([]);
    axios
      .get(`/v1/plans?current=${page + 1}&pageSize=${rowsPerPage}`)
      .then((res) => {
        const planningList = res.data.data.map((row) => ({
          ...row,
          id: <Link to={`/planejamentos/${row.id}`}>PLAN-00{row.id}</Link>,
          startDate: formatDate(row.startDate),
          status: statusDict[row.status],
        }));
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <PlanningLayout>
      <Paper sx={{ width: "100%", paddingTop: "1em" }}>
        <TableContainer>
          <div
            style={{
              marginLeft: "1.3%",
              display: "flex",
              flexDirection:
                selectedReportType?.id === 1 || selectedReportType?.id === 2 ? "column" : "row",
            }}
          >
            <div style={{ display: "flex" }}>
              <div>
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    options={customerName}
                    value={{ id: 0, name: selectCustomer?.name ?? "" }}
                    onChangeInput={(event, value, reason) => {
                      if (reason === "clear") {
                        setSelectedReportType("");
                        setSelectCustomer("");
                        setSelectedProgram("");
                        setFarm("");
                        setSoil("");
                        setSelectedSoil("");
                        setProgramList("");
                      }
                    }}
                    getOptionLabel={(option) => option.name}
                    id="combo-box-demo"
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Filtrar por Cliente" />}
                    onChange={(event, value) => setSelectCustomer(value)}
                    disabled={user?.role !== "admin"}
                  />
                </FormControl>
              </div>
              <div
                style={{
                  marginLeft: "1.3%",
                }}
              >
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    options={typesRelatorios}
                    value={{ id: 0, name: selectedReportType?.name ?? "" }}
                    getOptionLabel={(option) => option.name}
                    id="combo-box-demo"
                    sx={{ width: 300 }}
                    disabled={selectCustomer === undefined}
                    renderInput={(params) => <TextField {...params} label="Tipo de Relatório" />}
                    onChange={(event, value) => {
                      setSelectedReportType(value);
                      setReports([]);
                      setPlan([]);
                      setProgramList([]);
                    }}
                  />
                </FormControl>
              </div>
            </div>
            <div>{renderFilterComponents()}</div>
          </div>
          <DataTable
            table={{ columns, rows }}
            entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20] }}
            showTotalEntries
            isSorted={false}
            noEndBorder
            pageSize={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            pageIndex={page}
            gotoPage={setPage}
            totalRows={totalRows}
            pageOptions={pageOptions}
            isLoading={loading}
          />
        </TableContainer>
      </Paper>
    </PlanningLayout>
  );
}
